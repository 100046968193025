const LeaderboardCookie = {

    setup: function() {
        this.$leaderboard = $('.leaderboard');
        this.$btn_close = this.$leaderboard.find('.button__close');
        this.leaderboardCookie = CookieUtils.getCookie('leaderboard_closed');
    },

    show: function () {
        if (this.leaderboardCookie) {
            this.$leaderboard.hide();
            document.documentElement.style.setProperty('--breadcrumb-position', 95 + "px")
            
        } else{
            this.$leaderboard.show();
            let leaderboardHeight = this.$leaderboard.innerHeight() + 90;
            if(typeof doofinder !== 'undefined'){
                document.getElementById('css-added-rules').sheet.insertRule('#dffullscreen.df-fullscreen { top: ' + leaderboardHeight + 'px; }', 0);
            }
        }
        
    },

    close: function () {
        CookieUtils.createCookie('leaderboard_closed', '1', 1);

        document.documentElement.style.setProperty('--breadcrumb-position', 95 + "px")
        this.$leaderboard.slideUp().promise().done(() => {
            headerLayerPosition.setMarginTop();

            //resize height new-menu
            if(typeof header != "undefined"){
                header.refresh()
            }

        });
        if(typeof doofinder !== 'undefined') {
            document.getElementById('css-added-rules').sheet.insertRule('#dffullscreen.df-fullscreen { top: 100px !important; }', 0);
        }
    },
    

    bindEvents:  function()  {
        this.show();

        this.$btn_close.on('click', function(){
            this.close();
        }.bind(this));
    },

    init: function () {
        try {
            this.setup();

            if(this.$leaderboard.length ) {
                this.bindEvents();
            }
        }
        catch (e) {
            console.error(e); 
        } 
    }
};

$(document).ready(function () {
    LeaderboardCookie.init();
});


$('.leaderboard .sliders').slick({
    dots: false,
    arrows:false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 8000,
    variableWidth: true,
});

$('.leaderboard .sliders').on('init', function(event, slick){
    LeaderboardCookie.show();
});